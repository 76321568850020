import React, { PureComponent } from "react";
import { Feed, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
//import { generateMessage } from "../../constants/Notifications";
import ReactTimeAgo from "react-time-ago/no-tooltip";

import styles from "./Notifications.module.css";

class Notifications extends PureComponent {
	render() {
		const { onDelete } = this.props;
		//const { created, code, data, type, id } = this.props.data;
		const { date, text, type, id } = this.props.data;
	
		//const date = new Date(created);
		//const language = this.props.settings.language;
		//const text = generateMessage(code, language, data);
		switch (type) {
			case "Draw":
				return (
					<div className={styles.notificationContainer} style={{ backgroundColor: "#cfe2f3" }}>
						<Feed size={"small"}>
							<Feed.Event>
								<Feed.Content>
									<div className={styles.feed}>
										<Feed.Extra text className={styles.feedText}>
											<Icon color={"black"} name={"calendar alternate outline"} className={styles.feedIcon} />
											{text}
										</Feed.Extra>
										<Icon
											color={"black"}
											name={"trash"}
											className={styles.deleteIcon}
											onClick={e => {
												onDelete(e, id);
											}}
										/>
									</div>
									<Feed.Date
										className={styles.notificationTimeStyle}
										content={<ReactTimeAgo timeStyle="time" date={date} />}
									/>
								</Feed.Content>
							</Feed.Event>
						</Feed>
					</div>
				);

			case "Boulpik":
				return (
					<div className={styles.notificationContainer} style={{ backgroundColor: "#fce5cd" }}>
						<Feed size={"small"}>
							<Feed.Event>
								<Feed.Content>
									<div className={styles.feed}>
										<Feed.Extra text className={styles.feedText}>
											<Icon color={"black"} name={"hashtag"} className={styles.feedIcon} />
											{text}
										</Feed.Extra>
										<Icon
											color={"black"}
											name={"trash"}
											className={styles.deleteIcon}
											onClick={e => {
												onDelete(e, id);
											}}
										/>
									</div>
									<Feed.Date
										className={styles.notificationTimeStyle}
										content={<ReactTimeAgo timeStyle="time" date={date} />}
									/>
								</Feed.Content>
							</Feed.Event>
						</Feed>
					</div>
				);

			case "credit":
				return (
					<div className={styles.notificationContainer} style={{ backgroundColor: "#d9ead3" }}>
						<Feed size={"small"}>
							<Feed.Event>
								<Feed.Content>
									<div className={styles.feed}>
										<Feed.Extra text className={styles.feedText}>
											<Icon color={"black"} name={"money"} className={styles.feedIcon} />
											{text}
										</Feed.Extra>
										<Icon
											color={"black"}
											name={"trash"}
											className={styles.deleteIcon}
											onClick={e => {
												onDelete(e, id);
											}}
										/>
									</div>
									<Feed.Date
										className={styles.notificationTimeStyle}
										content={<ReactTimeAgo timeStyle="time" date={date} />}
									/>
								</Feed.Content>
							</Feed.Event>
						</Feed>
					</div>
				);

			case "System":
				return (
					<div className={styles.notificationContainer} style={{ backgroundColor: "#fff2cc" }}>
						<Feed size={"small"}>
							<Feed.Event>
								<Feed.Content>
									<div className={styles.feed}>
										<Feed.Extra text className={styles.feedText}>
											<Icon color={"black"} name={"warning circle"} className={styles.feedIcon} />
											{text}
										</Feed.Extra>
										<Icon
											color={"black"}
											name={"trash"}
											className={styles.deleteIcon}
											onClick={e => {
												onDelete(e, id);
											}}
										/>
									</div>
									<Feed.Date
										className={styles.notificationTimeStyle}
										content={<ReactTimeAgo timeStyle="time" date={date} />}
									/>
								</Feed.Content>
							</Feed.Event>
						</Feed>
					</div>
				);
			default:
				return null;
		}
	}
}

function mapStateToProps({ settings }) {
	return { settings };
}

export default connect(mapStateToProps)(Notifications);
