import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, Popup, Label } from "semantic-ui-react";
import { navigate } from "../../redux/actions/navigation";
//import { deleteNotification } from "../../redux/actions/notifications";
import { isEmpty } from "lodash";

//Components
import Notifications from "../Notifications/Notifications";

//Styles
import styles from "./Header.module.css";
import Menu from "../../components/Menu/Menu";

class Header extends PureComponent {
	handleBack = () => {
		const { pathname } = this.props.location;
		const path = pathname.split("/");
		const array = path.filter((item) => {
			return item !== "";
		});
		let route = "";
		for (let i = 0; i < array.length - 1; i++) {
			route += "/" + array[i];
		}
		this.props.dispatch(navigate(route));
	};

	handleDeleteNotification = (e, id) => {
		e.preventDefault();
		//this.props.dispatch(deleteNotification(id));
	};

	renderNotifications = (array) => {
		if (!isEmpty(array)) {
			const notifications = array.map((item, index) => {
				return <Notifications key={index} data={item} onDelete={this.handleDeleteNotification} />;
			});
			return notifications;
		} else {
			return [];
		}
	};

	render() {
		const { title, toggleMenu, nested, navigation, location, notification } = this.props;

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		if (nested) {
			return (
				<div className={styles.headerContainer}>
					<div className={styles.iconContainer} onClick={this.handleBack}>
						<Icon name={"arrow left"} size={"large"} />
					</div>
					<div className={styles.titleContainer}>{title}</div>
				</div>
			);
		}
		return (
			<div className={styles.headerContainer}>
				<div className={styles.iconContainer} onClick={toggleMenu}>
					<Menu user={this.props.user}/>
				</div>
				<div className={styles.titleContainer}>{title}</div>
				<div className={styles.endIconContainer}>
					<Popup
						className={styles.popup}
						trigger={
							<div>
								<Label size={"tiny"} style={{ position: "relative" }} circular floating color={"red"}>
									{notification.notifications.length}
								</Label>
								<Icon name={"alarm"} size={"large"} color={"black"} />
							</div>
						}
						disabled={isEmpty(notification.notifications)}
						content={<div className={styles.notification}>{this.renderNotifications(notification.notifications)}</div>}
						on={"click"}
						position={"bottom right"}
						basic
						size={"tiny"}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ navigation, notification, user }) {
	//console.log("user Header : ",user);
	return { navigation, notification, user  };
}

export default withRouter(connect(mapStateToProps)(Header));
