import React, { useEffect, useState } from 'react';
import { Card, Statistic, Grid, Header, Dropdown } from 'semantic-ui-react';
import Container from '../../components/Container/Container';
import { getLabel } from '../../constants/Strings';
import styles from './DashboardScreen.module.css';

const monthsOptions = [
  { key: 1, text: 'January', value: 1 },
  { key: 2, text: 'February', value: 2 },
  { key: 3, text: 'March', value: 3 },
  { key: 4, text: 'April', value: 4 },
  { key: 5, text: 'May', value: 5 },
  { key: 6, text: 'June', value: 6 },
  { key: 7, text: 'July', value: 7 },
  { key: 8, text: 'August', value: 8 },
  { key: 9, text: 'September', value: 9 },
  { key: 10, text: 'October', value: 10 },
  { key: 11, text: 'November', value: 11 },
  { key: 12, text: 'December', value: 12 },
];

const DashboardScreen = () => {
  const [totalUsers, setTotalUsers] = useState(1200);
  const [monthlyUsers, setMonthlyUsers] = useState(300);
  const [dailyUsers, setDailyUsers] = useState(15);
  const [weeklyUsers, setWeeklyUsers] = useState(70);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month

  // Mock API call to get the user counts (replace these with actual API calls)
  useEffect(() => {
    // Replace these with actual API calls based on `selectedMonth`
    setTotalUsers(1200); // Total users for all months
    setMonthlyUsers(300); // Users registered in selected month
    setDailyUsers(15);    // Users registered today
    setWeeklyUsers(70);   // Users registered this week
  }, [selectedMonth]);

  const handleMonthChange = (e, { value }) => {
    setSelectedMonth(value);
  };

  return (
    <Container title={getLabel("Dashboard")}>
      <div className={styles.dashboardContainer}>
        <Header as="h2" textAlign="center" className={styles.header}>
          User Registration Dashboard
        </Header>
        <Dropdown
          placeholder="Select Month"
          fluid
          selection
          options={monthsOptions}
          onChange={handleMonthChange}
          value={selectedMonth}
          className={styles.monthDropdown}
        />
        <Grid columns={4} stackable textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Card>
                <Statistic>
                  <Statistic.Value>{totalUsers}</Statistic.Value>
                  <Statistic.Label style={{ textAlign: 'right' }}>Total Users</Statistic.Label>
                </Statistic>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card>
                <Statistic>
                  <Statistic.Value>{monthlyUsers}</Statistic.Value>
                  <Statistic.Label style={{ textAlign: 'right' }}>Registered This Month</Statistic.Label>
                </Statistic>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card>
                <Statistic>
                  <Statistic.Value>{weeklyUsers}</Statistic.Value>
                  <Statistic.Label style={{ textAlign: 'right' }}>Registered This Week</Statistic.Label>
                </Statistic>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card>
                <Statistic>
                  <Statistic.Value>{dailyUsers}</Statistic.Value>
                  <Statistic.Label style={{ textAlign: 'right' }}>Registered Today</Statistic.Label>
                </Statistic>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  );
};

export default DashboardScreen;
