import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Image, Divider, Input, Button, Dropdown } from "semantic-ui-react";
import { transferFundMoncash, rechargeMoncash } from "../../redux/actions/user";
import Menu from "../../components/Menu/Menu";
import styles from "./OperationScreen.module.css";
import ImageButton from "../../components/ImageButton/ImageButton";
import { getLabel } from "../../constants/Strings";

class OperationScreen extends PureComponent {
  constructor(props) {
    super(props);
    const { phone } = props.user;
    const initialPhoneSuffix = phone.startsWith("509") ? phone.substring(3) : phone;

    this.state = {
      credit: "",
      isSuccessMessage: false,
      errorMessage: "",
      isTransaction: false,
      showModalLowBalance: false,
      operationType: "",
      alternatePhoneSuffix: initialPhoneSuffix,
      options: [
        { key: "retrait", value: "Retrait", text: "Retrait" },
        { key: "recharge", value: "Recharge", text: "Recharge" },
      ],
    };
  }

  handleButtonPress = async (type) => {
    const { credit, alternatePhoneSuffix } = this.state;
    const usePhone = "509" + alternatePhoneSuffix;
    if (type === "MonCash") {
      if (credit !== "") {
        this.windowRef = window.open();
        this.props.dispatch(rechargeMoncash(credit, usePhone, this.windowRef));
      }
    }
  };

  handleOperationChange = (e, { value }) => {
    this.setState({ operationType: value, credit: "" }); // Réinitialise le montant lorsque le type d'opération change
  };

  handleInputOnChange = (e) => {
    const { name, value } = e.target;
    const { balance } = this.props.user.profile;
    
    if (name === "credit") {
      const amount = parseFloat(value);

      if (amount < 1) {
        this.setState({ credit: "" });
        return;
      }

      if (this.state.operationType === "Retrait" && amount > balance) {
        this.setState({ credit: balance.toFixed(2) });
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handlePhoneInputOnChange = (e) => {
    const { value } = e.target;
    const phoneSuffix = value.startsWith("509") ? value.substring(3) : value;
    this.setState({ alternatePhoneSuffix: phoneSuffix });
  };

  handleWithdraw = () => {
    const { credit, alternatePhoneSuffix } = this.state;
    const amount = parseFloat(credit);
    const phone = "509" + alternatePhoneSuffix;
    const { balance } = this.props.user.profile;

    if (alternatePhoneSuffix.startsWith("509") || phone.length !== 11) {
      this.setState({ errorMessage: "Numéro de téléphone invalide." });
      return;
    }

    if (amount > 0 && amount <= balance) {
      this.props.dispatch(
        transferFundMoncash(amount, phone, this.onSuccess)
      );
    } else {
      this.setState({ showModalLowBalance: true });
    }
  };

  onSuccess = (response) => {
    if (response === "true") {
      this.setState((prevState) => ({
        isSuccessMessage: true,
        errorMessage: "",
        balance: prevState.balance - parseFloat(prevState.credit),
      }));
      this.props.history.push("/transaction");
    } else {
      this.setState({
        isSuccessMessage: false,
        errorMessage: "La transaction a échoué ou a été annulée.",
      });
    }
  };

  closeModal = () => this.setState({ showModalLowBalance: false });

  renderOperationType = () => {
    const { balance } = this.props.user.profile || { balance: 0 };
    const { credit, alternatePhoneSuffix, operationType, errorMessage } = this.state;

    return (
      <>
        <p className={styles.balanceDisplay}>
          Balance Actuelle : ${balance.toFixed(2)}
        </p>

        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

        <div className={styles.inputContainer}>
          <Input
            icon="phone"
            label="509"
            name="alternatePhoneSuffix"
            placeholder="numero telephone"
            value={alternatePhoneSuffix}
            onChange={this.handlePhoneInputOnChange}
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </div>

        <div className={styles.inputContainer}>
          <Input
            icon={operationType === "Retrait" ? "minus" : "plus"}
            name="credit"
            type="number"
            value={credit}
            placeholder={`Entrer le montant du ${operationType.toLowerCase()}`}
            onChange={this.handleInputOnChange}
            min="1"
            step="1"
            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
          />
        </div>

        {operationType === "Retrait" ? (
          <div className={styles.inputContainer}>
            <Button
              onClick={this.handleWithdraw}
              style={{ backgroundColor: "#F99500", color: "white" }}
              className={styles.buttonStyle}
            >
              Retrait
            </Button>
          </div>
        ) : (
          <>
            <Divider horizontal className={styles.divider}>
              {getLabel("RechargeWith")}
            </Divider>
            <ImageButton type="MonCash" onPress={this.handleButtonPress} />
          </>
        )}
      </>
    );
  };

  render() {
    const { options, operationType } = this.state;
    const { user } = this.props;
    return (
      <div className={styles.container}>
        <Menu user={user} />
        <Image src={"/logo3_1.png"} className={styles.logo} />
        <div className={styles.dropdownContainer}>
          <Dropdown
            className={styles.dropdownStyle}
            placeholder="Choisir Une Option"
            fluid
            selection
            options={options}
            onChange={this.handleOperationChange}
            value={operationType}
          />
        </div>
        {operationType && this.renderOperationType()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(OperationScreen);
