import React, { useState } from "react";
import { Form, Checkbox, Button, Select, Input, TextArea } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "../../redux/actions/notifications";
import styles from "./NotificationScreen.module.css";
import Container from "../../components/Container/Container";
import { getLabel } from "../../constants/Strings";

const NotificationScreen = () => {
  const dispatch = useDispatch();
  const [isAllUsers, setIsAllUsers] = useState(false);
  const [userPhoneSuffix, setUserPhoneSuffix] = useState('');
  const [message, setMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Access success or error messages from Redux state if needed
  const successMessage = useSelector(state => state?.notifications?.successMessage);
  const apiError = useSelector(state => state?.notifications?.errorMessage);

  const notificationOptions = [
    { key: 'alert', text: 'System', value: 'System' }
  ];

  const handlePhoneInputOnChange = (e) => {
    const { value } = e.target;
    const phoneSuffix = value.replace(/\D/g, '').substring(0, 8); // Only allow numeric input and limit to 8 digits
    setUserPhoneSuffix(phoneSuffix);
  };

  const handleSendNotification = () => {
    if (!isAllUsers && userPhoneSuffix.length !== 8) {
      setErrorMessage("Numéro de téléphone invalide.");
      return;
    }

    // Construct the notification data
    const notificationData = {
      type: notificationType,
      text: message,
      isSystem: isAllUsers,
      ...(isAllUsers ? {} : { numero: "509" + userPhoneSuffix })
    };

    // Dispatch the createNotification action
    dispatch(createNotification(notificationData));

    // Clear the form fields after dispatching the action
    setErrorMessage("");
    setUserPhoneSuffix('');
    setMessage('');
    setNotificationType('');
    setIsAllUsers(false);
  };

  return (
    <Container title={getLabel("Notification")}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.titleText}>Manage Notifications</h2>
          <Form className={styles.formContainer}>
            <Form.Field
              control={Select}
              label='Notification Type'
              options={notificationOptions}
              placeholder='Select Type'
              value={notificationType}
              onChange={(e, { value }) => setNotificationType(value)}
            />
            <Form.Field>
              <Checkbox
                label='Send to All Users'
                checked={isAllUsers}
                onChange={() => {
                  setIsAllUsers(!isAllUsers);
                  setErrorMessage('');
                }}
              />
            </Form.Field>
            {!isAllUsers && (
              <Form.Field>
                <label>User Phone Number</label>
                <Input
                  icon="phone"
                  label="509"
                  placeholder="Enter phone number"
                  value={userPhoneSuffix}
                  onChange={handlePhoneInputOnChange}
                  type="text"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
                {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
              </Form.Field>
            )}
            <Form.Field
              control={TextArea}
              label='Message'
              placeholder='Write your message...'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
            {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
            {apiError && <p className={styles.errorMessage}>{apiError}</p>}
            <Form.Field>
              <Button className={styles.buttonStyle} onClick={handleSendNotification} primary>
                Send Notification
              </Button>
            </Form.Field>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default NotificationScreen;
