//Api
export const API_START = "API_START";
export const API_END = "API_END";
export const API_ERROR = "API_ERROR";
export const API_FAILURE = "API_FAILURE";

//Error
export const DISMISS_ERROR = "DISMISS_ERROR";

//Success
export const DISMISS_SUCCESS = "DISMISS_SUCCESS";
export const ON_SUCCESS = "ON_SUCCESS";

//User
export const LOGIN = "LOGIN";
export const LOGGED_USER = "LOGGED_USER";
export const REGISTER_CLIENT = "REGISTER_CLIENT";
export const LOGOUT = "LOGOUT";

export const EDIT_USER = "EDIT_USER";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_PIN = "VERIFY_PIN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const DISMISS_MODAL = "DISMISS_MODAL";
export const TOTAL_JOUEUR = "TOTAL_JOUEUR";
export const UPDATE_USER_CREDIT_PAYLOAD = "UPDATE_USER_CREDIT_PAYLOAD";



//Settings
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const REHYDRATE_SETTINGS = "REHYDRATE_SETTINGS";

//Navigation
export const NAVIGATE = "NAVIGATE";
export const END_NAVIGATE = "END_NAVIGATE";

//Prize
export const GET_PRIZES = "GET_PRIZES";

//Boulpik
export const RESET_BOULPIK = "RESET_BOULPIK";
export const CREATE_BOULPIK = "CREATE_BOULPIK";
export const GET_BOULPIKS = "GET_BOULPIKS";
export const AUTO_BOULPIK = "AUTO_BOULPIK";
export const INFO_BOULPIKS = "INFO_BOULPIKS";


//History
export const GET_HISTORY = "GET_HISTORY";

//Transactions
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const NEW_TRANSACTION = "NEW_TRANSACTION";

//Cart
export const GET_CART = "GET_CART";
export const ADD_BOULPIK_TO_CART = "ADD_BOULPIK_TO_CART";
export const DELETE_CART = "DELETE_CART";
export const BUY_ALL_FROM_CART = "BUY_ALL_FROM_CART";
export const BUY_ONE_FROM_CART = "BUY_ONE_FROM_CART";

//Draws
export const GET_DRAW = "GET_DRAW";
export const CREATE_DRAW = "CREATE_DRAW";
export const GET_DETAIL_DRAW = "GET_DETAIL_DRAW";
export const SENT_EMAIL = "SENT_EMAIL";
export const UPDATE_DRAW = "UPDATE_DRAW";





//INVERSTORS
export const GET_INVERSTORS = "GET_INVERSTORS";


//Authorized Dealers
export const GET_DEALERS = "GET_DEALERS";

//City
export const GET_CITIES = "GET_CITIES";
export const GET_ZONES = "GET_ZONES";

//Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const INSERT_NOTIFICATION = "INSERT_NOTIFICATION";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";

//Shares Control
export const SHARE_PERCENT = "SHARE_PERCENT";
export const VALUE_SHARE_PERCENT = "VALUE_SHARE_PERCENT";


//Updates
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_BONUS = 'UPDATE_BONUS';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';


export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';


export const VERIFY_PIN_FAILURE = 'VERIFY_PIN_FAILURE';
export const CAN_WIN = 'CAN_WIN';

export const SET_PAYMENT_URL = 'SET_PAYMENT_URL';
 


