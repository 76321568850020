import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Image, Divider, Modal, Input, Form } from "semantic-ui-react";
import { transferFundMoncash } from "../../redux/actions/user";
import Menu from "../../components/Menu/Menu";
import styles from "./WithDrawScreen.module.css";

class WithDrawScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      credit: "",
      isSuccessMessage: false,
      errorMessage: "",
      isTransaction: false,
      showModalLowBalance: false,
      alternatePhone: "",
      useAlternatePhone: false,
    };
  }

  handleInputOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  togglePhoneUse = (useAlternate) => {
    this.setState({ useAlternatePhone: useAlternate });
  };

  handleWithdraw = () => {
    const { credit, useAlternatePhone, alternatePhone } = this.state;
    const amount = parseFloat(credit);
    const phone = useAlternatePhone ? alternatePhone : this.props.user.phone;
    const { balance } = this.props.user.profile;

    if (amount > 0 && amount <= balance) {
      this.props.dispatch(
        transferFundMoncash( amount, phone, this.onSuccess)
      );
    } else {
      this.setState({ showModalLowBalance: true });
    }
  };

  onSuccess = (response) => {
    if (response === "true") {
      this.setState((prevState) => ({
        isSuccessMessage: true,
        errorMessage: "",
        balance: prevState.balance - parseFloat(prevState.credit),
      }));
      this.props.history.push("/balance-updated");
    } else {
      this.setState({
        isSuccessMessage: false,
        errorMessage: "Transaction failed or was cancelled.",
      });
    }
  };

  closeModal = () => this.setState({ showModalLowBalance: false });

  render() {
    const { balance } = this.props.user.profile || { balance: 0 };
    const { credit, showModalLowBalance, alternatePhone, useAlternatePhone } =
      this.state;
    const { phone } = this.props.user;

    return (
      <div className={styles.container}>
        <Menu user={{}}/>
        <Image src={"/logo3_1.png"} className={styles.logo} />
        <p className={styles.balanceDisplay}>
          Current Balance: ${balance.toFixed(2)}
        </p>
        <Form>
          <div className={styles.buttonGroup}>
            <Button
              toggle
              active={!useAlternatePhone}
              className={
                useAlternatePhone ? styles.inactiveButton : styles.activeButton
              }
              onClick={() => this.togglePhoneUse(false)}
            >
              Phone: {phone}
            </Button>
            <Button
              toggle
              active={useAlternatePhone}
              className={
                useAlternatePhone ? styles.activeButton : styles.inactiveButton
              }
              onClick={() => this.togglePhoneUse(true)}
            >
              Change Number
            </Button>
          </div>
          {useAlternatePhone && (
            <Form.Field>
              <Input
                className={styles.inputField}
                icon="phone"
                placeholder="Enter a different phone number"
                value={alternatePhone}
                onChange={(e) =>
                  this.setState({ alternatePhone: e.target.value })
                }
                type="text"
              />
            </Form.Field>
          )}
          <Form.Field>
            <Input
              icon="minus"
              name="credit"
              type="number"
              value={credit}
              placeholder={`Enter amount to withdraw`}
              onChange={this.handleInputOnChange}
            />
          </Form.Field>
          <Button onClick={this.handleWithdraw} style={{ marginTop: "20px" }}>
            Withdraw
          </Button>
        </Form>

        <Divider className={styles.divider} />

        <Modal
          open={showModalLowBalance}
          onClose={this.closeModal}
          size="small"
        >
          <Modal.Header>Low Balance Alert</Modal.Header>
          <Modal.Content>
            <p>You do not have enough balance to make this withdrawal.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(WithDrawScreen));
