import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {  Button} from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { changeLanguage } from "../../redux/actions/settings";
import { endNavigate } from "../../redux/actions/navigation";
import Menu from "../../components/Menu/Menu";
import LanguagePicker from "../../components/LanguagePicker/LanguagePicker";

import styles from "./SettingsScreen.module.css";

class SettingsScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: props.settings.language,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  handleLanguageChange = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  handleSaveLanguage = () => {
    const { selectedLanguage } = this.state;
    this.props.dispatch(changeLanguage(selectedLanguage));
    this.props.history.push("/home-screen");
  };
  handleLanguageSelect = (language) => {
    this.setState({ selectedLanguage: language });
  };

  render() {
    const { selectedLanguage } = this.state;
    const languages = [
      {
        key: "english",
        value: "english",
        text: "English",
        flag: "us", 
      },
      {
        key: "french",
        value: "french",
        text: "Français",
        flag: "fr", 
      },
      {
        key: "creole",
        value: "creole",
        text: "Creole",
        flag: "ht", 
      },
      
    ];

    return (
      <div className={styles.container}>
        <div className={styles.menuIcon}>
          <Menu user={{}}/>
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLabel("ChoiceLanguage")}</div>

        
        <LanguagePicker
          selectedLanguage={selectedLanguage}
          onLanguageSelect={this.handleLanguageSelect}
          languages={languages}
        />

        <div className={styles.saveButtonPosition}>
          <Button
            primary
            onClick={this.handleSaveLanguage}
          >
            {getLabel("Save")}
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ navigation, settings }) {
  return { navigation, settings };
}

export default connect(mapStateToProps)(SettingsScreen);
