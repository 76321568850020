import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../../components/Menu/Menu";
import {  HEIGHT } from "../../constants/Layout";
import styles from "./Container.module.css";

class Container extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            dimmed: false,
        };
    }

    handleShowHideClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => ({ dimmed: !prevState.dimmed, visible: !prevState.visible }));
    };

    renderChildren = children => {
        return (
            <div className={styles.content} style={{ minHeight: HEIGHT - 60 }}>
                {children}
            </div>
        );
    };

    render() {
        const { children,user } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.menuIcon}>
                    <Menu user={user}/>
                </div>
                
                {this.renderChildren(children)}
            </div>
        );
    }
}

function mapStateToProps({ user }) {
    return { user };
}

export default withRouter(connect(mapStateToProps)(Container));
